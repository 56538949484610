import React from 'react'
import Image from '@/components/base/image'
import { Column, Row } from '@/components/base/gridview'
import Anchor from '@/components/base/anchor/Anchor'

import { useApp } from '@/core/contexts/app'

import { GlomilInfoBarProps } from './types'

const GlomilInfoBarDetail: React.FunctionComponent<GlomilInfoBarProps> = (
  props
) => {
  const app = useApp()

  const TextContent = () => {
    return (
      <Column
        xs={{ size: 12, order: 'first' }}
        sm={{ size: 12, order: 'first' }}
        xl={{ size: 6, order: props.isReverse ? 'last' : 'first' }}
        className="left"
      >
        <span
          style={{ textAlign: props.isReverse ? 'right' : 'left' }}
          className="group-title"
        >
          {props.title}
        </span>
        <p style={{ textAlign: props.isReverse ? 'right' : 'left' }}>
          <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
        </p>
        {props.staff.length > 0 && (
          <div className="staff">
            <div className="title">{app.settings.translations['staff']}</div>
            <div className="items">
              {props.staff.map((item, index) => (
                <figure key={index}>
                  <Image {...item.thumbnail} alt={item.name} />
                </figure>
              ))}
            </div>
          </div>
        )}

        {console.log(props.link)}

        {props.link.children === '' ? null : (
          <Anchor {...props.link} className="button">
            {props.link.children
              ? props.link.children
              : app.settings.translations['read-more']}
          </Anchor>
        )}
      </Column>
    )
  }
  const ImageContent = () => {
    return (
      <Column
        xs={{ size: 12, order: 'last' }}
        sm={{ size: 12, order: 'last' }}
        xl={{
          size: 6,
          order: props.isReverse ? 'first' : 'last',
          align: 'center',
        }}
        className="right"
      >
        <Anchor {...props.link}>
          <div className="thumbnail">
            <Image alt="" {...props.image} />
          </div>
        </Anchor>
      </Column>
    )
  }

  return (
    <>
      <Row
        className={`info-bar-wrapper ${props.isReverse && 'reverse'}`}
        gutter="md"
        id={props.title}
      >
        <React.Fragment>
          <TextContent />
          <ImageContent />
        </React.Fragment>
      </Row>
    </>
  )
}

export default GlomilInfoBarDetail
