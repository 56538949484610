import React, { useEffect, useState } from 'react'

import GlomilInfoBarDetail from '../glomil-info-bar-detail/GlomilInfoBarDetail'

import { GlomilInfoBarProps } from '../glomil-info-bar-detail/types'
import { GlomilInfoBarListProps } from './types'

const GlomilInfoBarList: React.FunctionComponent<GlomilInfoBarListProps> = ({
  data = [],
}) => {
  console.log()
  const [sortedData, setSortedData] = useState<null | GlomilInfoBarProps[]>(
    null
  )

  useEffect(() => {
    setSortedData(data)
  }, [data])

  return (
    <React.Fragment>
      {sortedData &&
        sortedData.map((item, index) => (
          <GlomilInfoBarDetail
            key={index}
            {...item}
            isReverse={index % 2 !== 0}
          />
        ))}
    </React.Fragment>
  )
}

export default GlomilInfoBarList
